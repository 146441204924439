body {
  font-family: "Inter", sans-serif;
  height: 100%;
}
html,
body {
  width: auto;
 
}

iframe {
  pointer-events: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
  background-color: rgba(0, 0, 0, 0.6);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}
input.checkbox-location {
cursor: pointer;
  width: 24px;
  height: 24px;
}
.select__value-container{
  height: 52px;
}
input[type="checkbox"]:disabled {
  background: #0F61D8
  ;
}
.css-1okebmr-indicatorSeparator{
  display: none;
}
.term-condition{
  text-decoration: none;
}
input:focus ~ svg {
  background-color: transparent !important;
}
.react-table{
  border-collapse: collapse;
}
.react-table th{
  padding-right: 10px;
  padding-left: 10px;  
  padding-top: 26px;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-ui-colors-text);
  font-family: Inter,sans-serif;
  line-height: 22.4px;
  text-align: left;
  border-bottom: 1px solid var(--theme-ui-colors-divider);
}
.react-table tbody tr:hover{
  background-color: rgba(233, 240, 251, 0.27);
}
.react-table td{
  padding-top: 26px;
  padding-bottom: 26px;
  padding-right: 10px;
  padding-left: 10px;  
  cursor: pointer;
  font-weight: 400;
  color: var(--theme-ui-colors-shade_400);
  font-size: 16px;
  line-height: 22.4px;
  border-bottom: 1px solid var(--theme-ui-colors-divider);
}
.react-table td svg{
  width: 34px;
  height: 34px;
}
.react-table th:first-child,.react-table td:first-child{
  padding-left: 40px;
}
.react-table th:last-child,.react-table td:last-child{
  padding-right: 40px;
}
.react-table td:first-child{
  color: var(--theme-ui-colors-shade_800);
}
.react-table .react-datepicker-wrapper input{
    background-color: white;
    border: 1px solid  var(--theme-ui-colors-shade_200);
    border-radius: 8px;
    padding: 9px;
    text-align: left;
    font-size: 16px;
    color: var(--theme-ui-colors-shade_400);
    height: 43.4px;
}

.cases-table .select-form-control__control{
  width: 150px;
 }
 
 .cases-table .react-table th{
  padding-top: 16px;
 }
 
 .cases-table .filter-col{
  position: relative;
 }
 
 .cases-table .filter-col > div{
  position: absolute;
  top: -90px;
 }