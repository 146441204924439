.header{
    height: 60px;
    background: #ffffff;
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.title{
    padding: 10px;
}
.profile-icon{
    padding: 10px;

}