@mixin fontSetting {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
}

.card {
  width: 946px;
  margin-top: 100px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  position: relative;
  margin: 0 auto;
  top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 56px 0px;
    flex-grow: 1;
    width: 100%;
    align-items: center;
  }
  &-transaction-amount {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 9;
    padding: 0 32px;
  }

 
  .line-chart {
    width: 90%;
    margin: 0 auto;
  }
}
.bottton-row {
  @include fontSetting();
  text-align: center;
  color: #0f223d;
  display: flex;
  justify-content: space-evenly;
  padding: 13px 32px;
}
.VictoryContainer{
  margin: 0 20px;
}
.filter-option {
  flex-grow: 1;
  padding-right: 10px;
}
