.btn-pills {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  padding: 11px;
 
}
.active-pills{
    background: #e2e5e9;
    border-radius: 32px;
}
.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.caribbeanGreen {
  background: #0c8d74;
}
.brightNavyBlue {
  background: #0F61D8;
}
.blue {
  background: #0A408F;
}
.brightNavyBlue {
  background: #0F61D8;
}
.caribbeanGreen {
  background: #11CCA8;
}
