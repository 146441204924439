 
 @mixin fontSetting {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
}
 .filters {
   @include fontSetting();
    flex-grow: 2;

    &-value {
      padding-right: 10px;
      color: #667b99;
      width: 79px;
      text-align: center;
      padding-left: 7px;
      cursor: pointer;
    }
    .active {
      color: #ffffff;
      padding: 4px 12px;
      width: 79px;
      background: #0f61d8;
      border-radius: 23px;
    }
  }