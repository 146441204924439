.overlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: 0px !important;
}
.react-datepicker__input-container input {
  width: 455px;
  margin: 0;
  min-width: 0;
  display: block;
  width: 100%;
  padding: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  border: 1px solid;
  border-radius: 4px;
  color: inherit;
  background-color: transparent;
  border: 1px solid;
  border-color: var(--theme-ui-colors-borderColor);
  padding: 17px;
  border-radius: 6px;
  --theme-ui-input-autofill-bg: var(--theme-ui-colors-background);
  height: 61px;
}